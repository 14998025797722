import styled from '@emotion/styled'
import React from 'react'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import Video from '../../components/panels/Video'
import useEmblaCarousel from 'embla-carousel-react';
import { PrevButton, NextButton, EmBlaCarouselArrowButtons } from "../panels/Button/EmblaCarouselArrowButtons";
import Autoplay from 'embla-carousel-autoplay'

const ContentBlockData = styled.div`
  display: block;
  justify-content: space-between;
  padding: 40px;
  margin: ${({ noBodyCopy }) => noBodyCopy ? 'auto 0' : ''};

  .slick-slide {
    padding: 20px 0;
  }
  .slick-prev {
    z-index: 3;
    position: relative;
    filter: invert(55%) sepia(86%) saturate(1200%) hue-rotate(75deg) brightness(102%) contrast(101%) ;
  }

  .slick-next {
    left: 2%;
    color: lightgreen;
    z-index: 3;
    position: relative;
    filter: invert(55%) sepia(86%) saturate(1200%) hue-rotate(75deg) brightness(102%) contrast(101%) ;
  }
  max-width: 800px;
  @media (min-width: 1026px) {
    ${(props) => (props.contentLeft ? 'justify-content: start; align-items: start;' : 'justify-content: end; align-items: end;')};
    width: ${(props) => (props.width ? `${props.width}%` : '50%')};
  }
  @media (max-width: 1025px) {
    ${(props) => (props.contentLeft ? 'justify-content: start; align-items: start;' : 'justify-content: end; align-items: end;')};
    padding: 0px;
    .slick-slider {
      margin: auto;
    }
    .slick-prev {
      color: lightgreen;
      z-index: 4;
    }

    .slick-next {
      left: 1%;
      color: lightgreen;
      z-index: 3;
    }
    padding: ${({ mobilePadding }) => mobilePadding ? '50px;' : ''};
    padding-top: 0;
    padding-bottom: 0;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #1E392A;
  flex-direction: ${(props) => (props.contentLeft ? 'row-reverse' : 'row')};

  @media (max-width: 768px) {
    display: block;
    margin: 0 auto;
  }
`
const Title = styled.div`
  letter-spacing: -2px;
  display: flex;
  justify-content: start;
  font-size: 72px;
  padding: 16px;
  margin: 0 auto;
  text-align: start;
  color: ${({ textColor }) => textColor ? textColor : 'white'};
  ${({ noBodyCopy }) => !noBodyCopy ? 'font-size: 64px; font-weight: 600;' : ''};
  ${({ noBodyCopy }) => noBodyCopy ? 'padding: 0 16px;' : ''};
  font-family: ${({ textColor }) => textColor === 'white' ? 'PPEditorialNew' : ''};
  font-weight: ${({ textColor }) => textColor === 'white' ? '300' : ''};
  font-style: ${({ textColor }) => textColor === 'white' ? 'italic' : ''};

  @media (max-width: 768px) {
    font-size: 42px;
    padding: 24px;
    padding-bottom: 12px;
    justify-content: center;
    text-align: center;
    line-height: 1.23;
  }
`
const SubTitle = styled.div`
  display: flex;
  color: #fff;
  justify-content: start;
  font-size: 16px;
  padding-bottom: 40px;
  padding: 0 16px;
  margin: 0 auto;
  font-family: monospace;
  color: ${({ textColor }) => textColor ? textColor : 'white'};
  ${({ noBodyCopy }) => !noBodyCopy ? 'font-size: 20px;' : ''};
  font-family: monospace;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0 24px;
    text-align: center;
    justify-content: center;
  }
`
const Body = styled.div`
  font-size: 16px;
  padding: 16px;
  color: #fff;
  max-width: 1000px;
  align-items: start;
  text-align: start;
  line-height: 1.39;
  color: ${({ textColor }) => textColor ? textColor : 'white'};
  ${({ noBodyCopy }) => !noBodyCopy ? 'margin: auto 0; font-size: 52px;' : ''};
  font-family: 'PPEditorialNew';
  font-style: italic;


  @media (max-width: 768px) {
    font-size: 16px;
    text-align: center;
    padding: 24px;
  }
`
const ImageLabel = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding-bottom: 24px;
  }
`
const Label = styled.span`
  font-size: 16px;
  padding: 12px 0;
  font-family: monospace;
  color: #fff;
`
const ImageContainer = styled(GatsbyImage)`
  border-radius: 5px;
  flex: 0 0 100%;
  min-width: 0;
  @media (min-width: 769px) {
    height: 100%;
  }
  @media (max-width: 768px) {
    height: 450px;
  }
`
const VideoContainer = styled(Video)`
  border-radius: 5px;
  @media (min-width: 769px) {
    height: 650px;
  }
  @media (max-width: 768px) {
    height: 450px;
  }
`
const BelowCopyImage = styled(GatsbyImage)`
  @media (min-width: 769px) {
    width: 200px;
    margin: 16px;
  }
  @media (max-width: 768px) {
    margin: 16px auto;
    width: 100px;
  }
`

const Embla = styled.div`
  overflow: hidden;
`

const EmblaContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ArrowContainer = styled.div`
  display: flex;
  position: relative;
  bottom: 2%;
  left: 6%
`

const ImageContentBlock = ({ data }) => {
  const noBodyCopy = data?.Body === null || data?.Body?.length === 0
  const containerDiv = data?.Title.replace(/\s+/g, '').toLowerCase().replace(/[^a-z0-9]/g, '')
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'keepSnaps',
    dragFree: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    loop: true,
  }, [
    Autoplay({ playOnInit: true, delay: 3000 })
  ]);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = EmBlaCarouselArrowButtons(emblaApi)

  return (
    <Content contentLeft={data?.ImagePosLeft}>
      <ContentBlockData contentLeft={data?.ImagePosLeft} width={60} noBodyCopy={noBodyCopy}>
        <span id={containerDiv} />
        <div style={{ position: 'sticky', top: 16 }}>
          <Title noBodyCopy={noBodyCopy} textColor={data?.PrimaryColor}>{data?.Title}</Title>
          <SubTitle noBodyCopy={noBodyCopy} textColor={data?.PrimaryColor}>{data?.SubTitle}</SubTitle>
          {
            !noBodyCopy && (
              <Body noBodyCopy={noBodyCopy} textColor={data?.SecondaryColor} dangerouslySetInnerHTML={{ __html: data?.Body }} />
            )
          }
          {
            data?.BelowCopyImage && (
              <BelowCopyImage image={data?.BelowCopyImage.file.childImageSharp.gatsbyImageData} />
            )
          }
        </div>
      </ContentBlockData>
      {data?.ContentImages[0].mime?.includes('video') ? (
        <ContentBlockData contentLeft={data?.ImagePosLeft} width={30} mobilePadding={true}>
          <VideoContainer alt='Splash screen'
            src={data?.ContentImages[0]?.file?.publicURL}
          />
          <ImageLabel>
            <Label>01</Label>
            <Label>{data?.UnderImageText}</Label>
          </ImageLabel>
        </ContentBlockData>
      ) : (
        <ContentBlockData contentLeft={data?.ImagePosLeft} width={40} mobilePadding={true}>
          {
            data?.ContentImages.length === 1 ? (
              <>
                <ImageContainer objectFit='cover' alt='Splash screen' image={
                  withArtDirection(
                    getImage(data?.ContentImages[0].file?.childImageSharp?.gatsbyImageData),
                    [
                      {
                        media: '(max-width: 1024px)',
                        image: getImage(data?.ContentImages[0].file?.childImageSharp?.gatsbyImageDataMobile),
                      },
                    ]
                  )} />
                <ImageLabel>
                  <Label>01</Label>
                  <Label>{data?.UnderImageText}</Label>
                </ImageLabel>
              </>
            ) : (
              <>
                <Embla ref={emblaRef}>
                  <EmblaContainer >
                    {
                      data?.ContentImages?.map((singleImage, index) => (
                        <ImageContainer objectFit='cover' alt='Splash screen' image={
                          withArtDirection(
                            getImage(singleImage.file?.childImageSharp?.gatsbyImageData),
                            [
                              {
                                media: '(max-width: 1024px)',
                                image: getImage(singleImage.file?.childImageSharp?.gatsbyImageDataMobile),
                              },
                            ]
                          )} />
                      ))
                    }
                  </EmblaContainer>
                </Embla>
              </>
            )
          }
          <ArrowContainer>
            <PrevButton  onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
            <NextButton  onClick={onNextButtonClick} disabled={nextBtnDisabled} />
          </ArrowContainer>
        </ContentBlockData>
      )
      }
    </Content >
  )
}

export default ImageContentBlock
